<div
  *ngIf="user"
  [class]="
    (user?.activeDevice?.info?.associatedPhone && !user.phoneRapidSynced) ||
    !user.rapidSynced
      ? 'd-flex justify-content-between align-items-center mb-2'
      : 'text-end mb-2'
  "
>
  <div
    class="warning-badge me-2"
    *ngIf="
      (user?.activeDevice?.info?.associatedPhone && !user.phoneRapidSynced) ||
      !user.rapidSynced
    "
  >
    <div class="d-flex">
      <i class="fas fa-info-circle text-warning"></i>
      <small
        >The Phone Number and Account Number has not been synchronized with
        Rapid Web. Please synchronize your information as highlighted below. If
        you continue to see this message, please contact the
        administrator.</small
      >
    </div>
  </div>
  <div class="d-flex">
    <button
      type="button"
      class="btn btn-primary me-2"
      style="min-width: 180px"
      (click)="onRemoveCadence()"
      [disabled]="isLoading.removeEmergencyCadence"
      *ngIf="user?.emergencyCadence"
    >
      <span
        class="spinner-grow spinner-grow-sm ms-1"
        role="status"
        aria-hidden="true"
        *ngIf="isLoading.removeEmergencyCadence"
      ></span
      >Remove Cadence
    </button>
    <button
      type="button"
      class="btn btn-primary"
      (click)="notesComponent.onOpenNotes()"
    >
      Notes
    </button>
  </div>
</div>

<app-notes
  [isFetchingNotes]="isLoading.notes"
  [data]="notes"
  [notedUserId]="userId"
></app-notes>

<div class="card mb-3">
  <!-- [style.height]="'238px'" -->
  <div class="card-body p-2">
    <div class="row w-100 m-0 p-0 display-flex justify-content-between">
      <ng-container *ngIf="user; then isLoaded; else notLoaded"></ng-container>
      <ng-template #isLoaded>
        <div class="col-sm-12 col-xs-12 col-lg-1 px-0">
          <span
            class="nav-link avatar avatar-lg p-0 bg-primary-dark text-center text-white fw-bold"
          >
            {{ userInitials }}
          </span>
        </div>
        <div class="col-sm-12 col-xs-12 col-lg-3 p-0">
          <div class="d-flex flex-column">
            <div class="row gx-2 mb-1">
              <div class="col-3 text-end">Name</div>
              <div class="col-9">
                <span
                  class="fw-bold cursor-pointer"
                  [mdbTooltip]="user?.demographic?.fullName"
                  >{{ user?.demographic?.fullName | trim : 20 : useTrim }}</span
                >
              </div>
            </div>
            <div class="row gx-2 mb-1">
              <div class="col-3 text-end">DOB</div>
              <div class="col-9">
                <span class="fw-bold">{{ user?.demographic?.dob }}</span>
              </div>
            </div>
            <div class="row gx-2 mb-1">
              <div class="col-3 text-end">Email</div>
              <div class="col-9">
                <span
                  class="fw-bold cursor-pointer"
                  [mdbTooltip]="user?.demographic?.email"
                  >{{ user?.demographic?.email | trim : 20 : useTrim }}</span
                >
              </div>
            </div>
            <div class="row gx-2 mb-1">
              <div class="col-3 text-end">Mobile</div>
              <div class="col-9">
                <span class="fw-bold">{{
                  user?.demographic?.phone.length === 10
                    ? (user?.demographic?.phone | formatPhoneNumber)
                    : user?.demographic?.phone
                }}</span>
              </div>
            </div>
            <div class="row gx-2 mb-1">
              <div class="col-3 text-end">Address</div>
              <div class="col-9">
                <span class="fw-bold"
                  >{{ user?.demographic?.address?.home?.addressLine1 }}
                  {{ user?.demographic?.address?.home?.addressLine2 }}</span
                ><br />
                <span class="fw-bold"
                  >{{
                    user?.demographic?.address?.home?.city +
                      ", " +
                      user?.demographic?.address?.home?.state +
                      " " +
                      user?.demographic?.address?.home?.zip
                  }}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div class="col-sm-12 col-xs-12 col-lg-3 p-0">
          <div class="d-flex flex-column">
            <div class="row gx-2 mb-1">
              <div class="col-3 col-lg-5 text-end">Status</div>
              <div class="col-9 col-lg-7">
                <span
                  class="fw-bold badge rounded-pill bg-opacity-25"
                  [ngClass]="{
                    'bg-primary text-primary':
                      user?.status?.toLowerCase() === userStatus.NEW,
                    'bg-success-light text-success':
                      user?.status?.toLowerCase() === userStatus.ACTIVATED,
                    'bg-warning text-warning':
                      user?.status?.toLowerCase() === userStatus.IN_PROGRESS
                  }"
                  >{{ user?.status }}</span
                >
              </div>
            </div>
            <div class="row gx-2 mb-1">
              <div class="col-3 col-lg-5 text-end">Device Name</div>
              <div class="col-9 col-lg-7">
                <span
                  class="fw-bold cursor-pointer"
                  [mdbTooltip]="user?.activeDevice?.info?.name"
                  >{{
                    user?.activeDevice?.info?.name | trim : 15 : useTrim
                  }}</span
                >
              </div>
            </div>
            <div class="row gx-2 mb-1">
              <div class="col-3 col-lg-5 text-end">Associated Phone</div>
              <div class="col-9 col-lg-7">
                <span class="fw-bold">{{
                  user?.activeDevice?.info?.associatedPhone?.length === 10
                    ? (user?.activeDevice?.info?.associatedPhone
                      | formatPhoneNumber)
                    : user?.activeDevice?.info?.associatedPhone
                }}</span>
                <i
                  *ngIf="
                    user?.activeDevice?.info?.associatedPhone &&
                    !user?.phoneRapidSynced &&
                    !isLoading.syncAssociatedPhoneNumber
                  "
                  class="fas fa-sync ms-1 text-danger"
                  style="cursor: pointer"
                  [mdbTooltip]="'Sync with Rapid Web'"
                  (click)="onSyncAssociatedPhoneInRapid()"
                ></i>
                <span
                  class="spinner-grow spinner-grow-sm ms-1"
                  role="status"
                  aria-hidden="true"
                  *ngIf="isLoading.syncAssociatedPhoneNumber"
                ></span>
              </div>
            </div>
            <div class="row gx-2 mb-1">
              <div class="col-3 col-lg-5 text-end">Model/Serial No.</div>
              <div class="col-9 col-lg-7">
                <span
                  class="fw-bold cursor-pointer"
                  [mdbTooltip]="user?.activeDevice?.info?.modelAndSerialNumber"
                  >{{
                    user?.activeDevice?.info?.modelAndSerialNumber
                      | trim : 15 : useTrim
                  }}</span
                >
              </div>
            </div>
            <div class="row gx-2 mb-1">
              <div class="col-3 col-lg-5 text-end">IMEI</div>
              <div class="col-9 col-lg-7">
                <span class="fw-bold">{{
                  user?.activeDevice?.info?.imeiNumber ||
                    user?.activeDevice?.info?.eidNumber
                }}</span>
              </div>
            </div>
            <div class="row gx-2 mb-1">
              <div class="col-3 col-lg-5 text-end">Watch App Version</div>
              <div class="col-9 col-lg-7">
                <span class="fw-bold">{{
                  user?.activeDevice.info.appVersionName ?? "N/A"
                }}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xs-12 col-sm-6 col-lg-3 p-0 border-start">
          <div>
            <div class="mb-2">
              <ul
                class="d-flex justify-content-between"
                style="list-style-type: none"
              >
                <li>
                  <button
                    class="btn btn-link btn-sm py-0"
                    [disabled]="isLoading.gpsCheck"
                    (click)="onGpsCheck()"
                  >
                    Check GPS
                  </button>
                </li>
                <li>
                  <button
                    class="btn btn-link btn-sm py-0"
                    [disabled]="isLoading.networkCheck"
                    (click)="onNetworkCheck()"
                  >
                    Check Network
                  </button>
                </li>
                <li>
                  <button
                    class="btn btn-link btn-sm py-0"
                    (click)="onDownloadLogs()"
                  >
                    Download Logs
                  </button>
                </li>
              </ul>
            </div>
            <div class="row gx-2 mb-1">
              <div class="col-3 text-end">GPS</div>
              <div class="col-9 d-flex align-items-center">
                <div *ngIf="!isLoading.gpsCheck">
                  <span
                    class="fw-bold badge rounded-pill bg-opacity-25 mb-1"
                    *ngIf="device.gps.status"
                    [ngClass]="{
                      'bg-success-light text-success':
                        device.gps.status === 'Available',
                      'bg-danger text-danger':
                        device.gps.status === 'Not Available',
                      'text-black': device.gps.status === 'N/A'
                    }"
                    >{{ device.gps.status }}</span
                  ><br />
                  <span
                    style="font-size: 11px"
                    *ngIf="device.gps.status !== 'N/A'"
                    >{{ device.gps.date }}</span
                  >
                </div>
                <span
                  class="spinner-grow spinner-grow-sm"
                  *ngIf="isLoading.gpsCheck"
                  role="status"
                  aria-hidden="true"
                ></span>
              </div>
            </div>
            <div class="row gx-2 mb-1">
              <div class="col-3 text-end">Network</div>
              <div class="col-9 d-flex align-items-center">
                <div *ngIf="!isLoading.networkCheck">
                  <span
                    class="fw-bold badge rounded-pill bg-opacity-25 mb-1"
                    *ngIf="device.network.status"
                    [ngClass]="{
                      'bg-success-light text-success':
                        device.network.status === 'Connected',
                      'bg-danger text-danger':
                        device.network.status === 'Not Connected',
                      'text-black': device.network.status === 'N/A'
                    }"
                    >{{ device.network.status }}</span
                  ><br />
                  <span
                    style="font-size: 11px"
                    *ngIf="device.network.date !== 'N/A'"
                    >{{ device.network.date }}</span
                  >
                </div>
                <span
                  class="spinner-grow spinner-grow-sm"
                  *ngIf="isLoading.networkCheck"
                  role="status"
                  aria-hidden="true"
                ></span>
              </div>
            </div>
          </div>
        </div>
        <div
          class="col-xs-12 col-sm-6 col-lg-1 d-flex align-items-center flex-wrap justify-content-end p-0"
        >
          <img
            style="width: 125px"
            [src]="
              user.watchAppVersion == 1
                ? '../../../assets/img/png/watch-analog.png'
                : '../../../assets/img/png/watch-digital.png'
            "
            class="watch"
          />
        </div>
      </ng-template>
      <ng-template #notLoaded>
        <div class="col-sm-12 col-xs-12 col-lg-1 px-0">
          <span class="">
            <app-skeleton-loader [config]="skeletonTheme"></app-skeleton-loader>
          </span>
        </div>
        <div class="col-sm-12 col-xs-12 col-lg-4 p-0">
          <div class="d-flex flex-column">
            <div class="row gx-2 mb-1">
              <div class="col-3 text-end">Name</div>
              <div class="col-9">
                <span class="fw-bold">
                  <app-skeleton-loader
                    [config]="skeletonTheme"
                  ></app-skeleton-loader>
                </span>
              </div>
            </div>
            <div class="row gx-2 mb-1">
              <div class="col-3 text-end">DOB</div>
              <div class="col-9">
                <span class="fw-bold">
                  <app-skeleton-loader
                    [config]="skeletonTheme"
                  ></app-skeleton-loader>
                </span>
              </div>
            </div>
            <div class="row gx-2 mb-1">
              <div class="col-3 text-end">Email</div>
              <div class="col-9">
                <span class="fw-bold text-nowrap">
                  <app-skeleton-loader
                    [config]="skeletonTheme"
                  ></app-skeleton-loader>
                </span>
              </div>
            </div>
            <div class="row gx-2 mb-1">
              <div class="col-3 text-end">Mobile</div>
              <div class="col-9">
                <span class="fw-bold">
                  <app-skeleton-loader
                    [config]="skeletonTheme"
                  ></app-skeleton-loader>
                </span>
              </div>
            </div>
            <div class="row gx-2 mb-1">
              <div class="col-3 text-end">Address</div>
              <div class="col-9">
                <span class="fw-bold">
                  <app-skeleton-loader
                    [config]="skeletonTheme"
                  ></app-skeleton-loader> </span
                ><br />
                <span class="fw-bold">
                  <app-skeleton-loader
                    [config]="skeletonTheme"
                  ></app-skeleton-loader>
                </span>
              </div>
            </div>
          </div>
        </div>
        <div class="col-sm-12 col-xs-12 col-lg-4 p-0">
          <div class="d-flex flex-column">
            <div class="row gx-2 mb-1">
              <div class="col-3 text-end">Status</div>
              <div class="col-9">
                <span class="fw-bold"
                  ><app-skeleton-loader
                    [config]="skeletonTheme"
                  ></app-skeleton-loader
                ></span>
              </div>
            </div>
            <div class="row gx-2 mb-1">
              <div class="col-3 text-end">Device Name</div>
              <div class="col-9">
                <span class="fw-bold"
                  ><app-skeleton-loader
                    [config]="skeletonTheme"
                  ></app-skeleton-loader
                ></span>
              </div>
            </div>
            <div class="row gx-2 mb-1">
              <div class="col-3 text-end">Associated Phone</div>
              <div class="col-9">
                <span class="fw-bold"
                  ><app-skeleton-loader
                    [config]="skeletonTheme"
                  ></app-skeleton-loader
                ></span>
              </div>
            </div>
            <div class="row gx-2 mb-1">
              <div class="col-3 text-end">Model/Serial No.</div>
              <div class="col-9">
                <span class="fw-bold"
                  ><app-skeleton-loader
                    [config]="skeletonTheme"
                  ></app-skeleton-loader
                ></span>
              </div>
            </div>
            <div class="row gx-2 mb-1">
              <div class="col-3 text-end">IMEI</div>
              <div class="col-9">
                <span class="fw-bold"
                  ><app-skeleton-loader
                    [config]="skeletonTheme"
                  ></app-skeleton-loader
                ></span>
              </div>
            </div>
            <div class="row gx-2 mb-1">
              <div class="col-3 text-end">Watch App Version</div>
              <div class="col-9">
                <span class="fw-bold"
                  ><app-skeleton-loader
                    [config]="skeletonTheme"
                  ></app-skeleton-loader
                ></span>
              </div>
            </div>
          </div>
        </div>
        <div class="col-2"></div>
      </ng-template>
    </div>
  </div>
</div>

<!-- Advocate Assist Dashboard -->
<div class="mb-2">
  <mdb-accordion [multiple]="false" class="accordion-block">
    <mdb-accordion-item [collapsed]="true">
      <ng-template mdbAccordionItemHeader>Emergency Details</ng-template>
      <ng-template mdbAccordionItemBody>
        <div class="row g-3" [style.height]="'350px'">
          <div class="col-lg-6">
            <div class="p-2 border rounded h-100">
              <p class="fw-bold">Emergency Event Map/Tracking</p>
              <div class="row">
                <div class="col-8">
                  <app-google-map
                    *ngIf="mapFeatures"
                    [height]="'260px'"
                    [features]="mapFeatures"
                    [customMarkers]="emergencyTriggeredLocations"
                  ></app-google-map>
                </div>
                <div class="col-3 d-flex align-items-center">
                  <table>
                    <tr
                      *ngFor="let constant of mapConstants"
                      class="map-legends"
                    >
                      <td
                        class="text-center"
                        [style.width]="'25px'"
                        [innerHTML]="constant.icon"
                      ></td>
                      <td>
                        <small class="fw-bold">{{ constant.label }}</small>
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-3">
            <div
              class="p-2 border rounded d-flex flex-column justify-content-between h-100"
            >
              <div class="blurred">
                <p class="fw-bold">Event Recordings</p>
                <small class="mb-1 fw-bold"
                  ><i class="fas fa-headphones"></i>&nbsp; Dispatch
                  Recordings</small
                >
                <ul
                  class="my-1 scrollbar-primary thin overflow-auto"
                  style="list-style-type: none; height: '170px'"
                >
                  <li *ngFor="let recording of recordings">
                    <a href="#"
                      ><small
                        ><i class="fas fa-play"></i>&nbsp;
                        {{ recording.name }}</small
                      ></a
                    >
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="col-lg-3">
            <div class="p-2 border rounded h-100">
              <p class="fw-bold">Case Information</p>
              <div class="mb-2">
                <table>
                  <tr>
                    <td [style.width]="'25px'">
                      <small><i class="fas fa-money-check"></i></small>
                    </td>
                    <td>
                      <small class="fw-bold">Account Number</small>
                      <i
                        *ngIf="
                          !user?.rapidSynced && !isLoading.syncAccountNumber
                        "
                        class="fas fa-sync ms-1 text-danger"
                        style="cursor: pointer"
                        [mdbTooltip]="'Sync with Rapid Web'"
                        (click)="onSyncAccountNumberInRapid()"
                      ></i>
                    </td>
                  </tr>
                  <tr>
                    <td></td>
                    <td>
                      <small>{{ user?.accountNumber }}</small>
                      <span
                        class="spinner-grow spinner-grow-sm ms-1"
                        role="status"
                        aria-hidden="true"
                        *ngIf="isLoading.syncAccountNumber"
                      ></span>
                    </td>
                  </tr>
                </table>
              </div>
              <div>
                <!-- <p class="fw-bold">RRMS - Mock Test</p> -->
                <div class="d-flex flex-column shadow-sm p-2">
                  <!-- <button class="btn btn-primary mb-2" (click)="onEmergencyClear()">
                    <small>Emergency Clear</small>
                  </button> -->
                  <button
                    class="btn btn-primary"
                    (click)="onDownloadRRMSLogs()"
                  >
                    <small>Download RRMS Logs</small>
                  </button>
                </div>
              </div>

              <!-- <div class="mb-2">
                <table>
                  <tr>
                    <td [style.width]="'25px'">
                      <small><i class="fas fa-user"></i></small>
                    </td>
                    <td>
                      <small class="fw-bold">Dependant/Perpetrator</small>
                    </td>
                  </tr>
                  <tr>
                    <td></td>
                    <td><small>John Q. Smith</small></td>
                  </tr>
                </table>
              </div>
              <div class="mb-2">
                <table>
                  <tr>
                    <td [style.width]="'25px'"><i class="fas fa-home"></i></td>
                    <td>
                      <small class="fw-bold">Home/Housing Details</small><br />
                    </td>
                  </tr>
                  <tr>
                    <td></td>
                    <td>
                      <small>1234 Main Street</small><br />
                      <small>Anytown, USA</small><br />
                    </td>
                  </tr>
                </table>
              </div>
              <div class="mb-2">
                <table>
                  <tr>
                    <td [style.width]="'25px'"><i class="fas fa-users"></i></td>
                    <td><small class="fw-bold">Family Information</small></td>
                  </tr>
                  <tr>
                    <td></td>
                    <td>
                      <small>Jane P. Smith (Mother)</small><br />
                      <small>Jim P. Smith (Father)</small>
                    </td>
                  </tr>
                </table>
              </div> -->
            </div>
          </div>
        </div>
      </ng-template>
    </mdb-accordion-item>
  </mdb-accordion>
</div>
<!-- Advocate Assist Dashboard -->

<div class="d-flex justify-content-end mb-3">
  <form [formGroup]="userDetailForm" autocomplete="off">
    <!-- <app-search></app-search> -->
    <div class="d-flex align-items-end">
      <!-- <button
                class="btn btn-gray-light btn-md avatar d-flex align-items-center justify-content-center me-2"
                [disabled]="loading"
                (click)="getFiltered(true)">
                <i class="fas fa-redo"></i>
            </button> -->
      <div class="me-2">
        <label for="From" class="form-label">Created From</label>
        <mdb-form-control>
          <div class="datepicker-wrapper">
            <input
              [readonly]="true"
              mdbInput
              [mdbDatepicker]="createdFrom"
              type="text"
              class="form-control"
              id="createdFrom"
              formControlName="createdFrom"
            />
            <button
              *ngIf="userDetailForm.value.createdFrom"
              class="datepicker-clear-button"
              (click)="clearDatePicked('createdFrom')"
            >
              <i class="fas fa-times"></i>
            </button>
            <mdb-datepicker-toggle
              [mdbDatepicker]="createdFrom"
            ></mdb-datepicker-toggle>
            <mdb-datepicker
              [inline]="true"
              [format]="mdbDateFormat"
              [maxDate]="getMaxDate()"
              #createdFrom
              (dateChanged)="getFiltered(true, true)"
            ></mdb-datepicker>
          </div>
        </mdb-form-control>
      </div>
      <div>
        <label for="To" class="form-label">Created To</label>
        <mdb-form-control>
          <div class="datepicker-wrapper">
            <input
              mdbInput
              [readonly]="true"
              [mdbDatepicker]="createdTo"
              type="text"
              class="form-control"
              id="createdTo"
              formControlName="createdTo"
            />
            <button
              *ngIf="userDetailForm.value.createdTo"
              class="datepicker-clear-button"
              (click)="clearDatePicked('createdTo')"
            >
              <i class="fas fa-times"></i>
            </button>
            <mdb-datepicker-toggle
              [mdbDatepicker]="createdTo"
            ></mdb-datepicker-toggle>
            <mdb-datepicker
              [inline]="true"
              [format]="mdbDateFormat"
              [minDate]="
                this.userDetailForm.controls['createdFrom'].value &&
                getMinDate()
              "
              [maxDate]="getMaxDate()"
              #createdTo
              (dateChanged)="getFiltered(true, true)"
            ></mdb-datepicker>
          </div>
        </mdb-form-control>
      </div>
      <!-- <app-select [form]="userDetailForm" [control]="userDetailForm.controls['limit']" [controlName]="'limit'"
                [filter]="false" [submitted]="submitted" [data]="limits" [name]="'label'" [value]="'value'" [width]="defaultLimitSelectWidth"
                (emitSelectedValue)="receiveSelectedValue($event)">
            </app-select> -->
    </div>
  </form>
</div>

<app-table
  [tableConfig]="tableConfig"
  [convertToLocal]="user?.watchAppVersion == 2"
  [module]="'Check in module'"
  [loading]="loading"
  (onScrollToBottom)="scrollToBottom($event)"
  [notFoundMessage]="notFoundMessage"
  (sendEvent)="receiveEvent($event)"
>
</app-table>
